import * as srp from '@getinsomnia/srp-js';
import type * as schema from '@getinsomnia/api-client/schema';
import * as util from '~/lib/fetch';
import * as Sentry from '@sentry/remix';
import type { PrivateKeyParams } from './e2ee.client';

const {
  Buffer,
  Client,
  computeVerifier,
  decryptAES,
  decryptRSAWithJWK,
  deriveKey,
  encryptAES,
  encryptRSAWithJWK,
  generateAccountId,
  generateAES256Key,
  generateKeyPairJWK,
  getRandomHex,
  params,
  srpGenKey,
  decodeBase64,
  encodeBase64,
  seal,
} = srp;

export {
  Buffer,
  Client,
  computeVerifier,
  decryptAES,
  decryptRSAWithJWK,
  deriveKey,
  encryptAES,
  encryptRSAWithJWK,
  generateAccountId,
  generateAES256Key,
  generateKeyPairJWK,
  getRandomHex,
  params,
  srpGenKey,
  decodeBase64,
  encodeBase64,
  seal,
};

export interface BillingEndpoint {
  planId: string;
  description: string;
  isPaymentRequired: boolean;
  isBillingAdmin: boolean;
  subTrialing: boolean;
  subTrialEnd: string;
  subCancelled: boolean;
  subPeriodEnd: string;
  subPercentOff: number;
  customerId: string;
  subQuantity: number;
  subMemo: string;
  hasCard: boolean;
  lastFour: string;
}

export interface TeamMember {
  isAdmin: boolean;
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  dateAccepted: string;
}

export interface Stats {
  projects: number;
  files: number;
  collaborators: number;
}
export interface Team {
  id: string;
  name: string;
  ownerAccountId: string;
  isPersonal: boolean;
  accounts: TeamMember[];
  created: string;
}

export interface Invoice {
  id: string;
  date: string;
  paid: boolean;
  total: number;
  downloadPdfUrl: string;
}

export interface InvoiceLink {
  downloadLink: string;
}

interface AuthSalts {
  saltKey: string;
  saltAuth: string;
}

export function deleteAccount() {
  return util.del('/auth/delete-account');
}

/**
 * Performs an SRP login. When useCookies is set to false, the server uses the
 * negotiated SRP K value to create a valid session token. When useCookies is
 * set to true, the SRP K value is discarded and a pseudo-random session cookie
 * is created upon login instead, using HTTP-only mode.
 *
 * authSecret never needs to be passed; it is only passed by other auth
 * functions when the authSecret value has already been computed for another
 * reason (such as during signup.)
 *
 * useCookies needs to be set to false if the client needs access to a valid
 * session token.
 *
 * @param rawEmail The raw e-mail identity.
 * @param rawPassphrase The raw passphrase.
 * @param authSecret If already calculated, the derived passphrase key.
 * @param useCookies If true, the server creates a psuedo-random session cookie.
 * @returns The SRP K value.
 */
export async function login(
  rawEmail: string,
  rawPassphrase: string,
  encDriverKey: string,
  authSecret: string | null = null,
  useCookies = true,
): Promise<string> {
  // ~~~~~~~~~~~~~~~ //
  // Sanitize Inputs //
  // ~~~~~~~~~~~~~~~ //

  const email = _sanitizeEmail(rawEmail);
  const passphrase = _sanitizePassphrase(rawPassphrase);

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //
  // Fetch Salt and Submit A To Server //
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

  const { saltKey, saltAuth } = await getAuthSalts(email);
  // marckong: we are passing encDriverKey value here instead of email as we allow primary email to be changed
  authSecret = authSecret || (await deriveKey(passphrase, encDriverKey, saltKey));
  const secret1 = await srpGenKey();
  const c = new Client(
    _getSrpParams(),
    Buffer.from(saltAuth, 'hex'),
    Buffer.from(encDriverKey, 'utf8'),
    Buffer.from(authSecret, 'hex'),
    Buffer.from(secret1, 'hex'),
  );
  const srpA = c.computeA().toString('hex');
  const { sessionStarterId, srpB } = await util.post<{
    sessionStarterId: string;
    srpB: string;
  }>('/auth/web-login-a', { srpA, email });

  // ~~~~~~~~~~~~~~~~~~~~~ //
  // Compute and Submit M1 //
  // ~~~~~~~~~~~~~~~~~~~~~ //

  c.setB(new Buffer(srpB, 'hex'));
  const srpM1 = c.computeM1().toString('hex');
  const { srpM2 } = await util.post<{
    srpM2: string;
  }>('/auth/web-login-m1', {
    srpM1,
    sessionStarterId,
    useCookies,
  });

  // ~~~~~~~~~~~~~~~~~~~~~~~~~ //
  // Verify Server Identity M2 //
  // ~~~~~~~~~~~~~~~~~~~~~~~~~ //

  c.checkM2(new Buffer(srpM2, 'hex'));

  // Return K
  return c.computeK().toString('hex');
}

export async function logout() {
  try {
    await util.post('/auth/logout');
  } catch (e) {
    // Not a huge deal if this fails, but we don't want it to prevent the
    // user from signing out.
    console.warn('Failed to logout', e);
    Sentry.captureException(e);
  }
}

export async function cancelAccount() {
  await util.del('/api/billing/subscriptions');
}

export async function whoami() {
  return util.get<schema.WhoamiResponse & { encDriverKey: string }>('/auth/whoami');
}

export async function keys() {
  return util.get<schema.APIKeysResponse>('/v1/keys');
}

export async function invoices() {
  return util.get<Invoice[]>('/api/billing/invoices');
}

export async function getInvoice(invoiceId: string) {
  return util.get<InvoiceLink>('/api/billing/invoices/' + invoiceId);
}

export async function verify() {
  return util.post('/auth/verify');
}

export function getAuthSalts(email: string) {
  return util.post<AuthSalts>('/auth/web-login-s', { email });
}

export async function changePasswordAndEmail(
  rawOldPassphrase: string,
  rawNewPassphrase: string,
  rawNewEmail: string,
  newFirstName: string,
  newLastName: string,
) {
  // Sanitize inputs
  const oldPassphrase = _sanitizePassphrase(rawOldPassphrase);
  const newPassphrase = _sanitizePassphrase(rawNewPassphrase);
  const newEmail = _sanitizeEmail(rawNewEmail);

  // Fetch some things
  const { email: oldEmail, saltEnc, encSymmetricKey } = await whoami();
  const { saltKey, saltAuth } = await getAuthSalts(oldEmail);

  // Generate some secrets for the user base'd on password
  const oldSecret = await deriveKey(oldPassphrase, oldEmail, saltEnc);
  const newSecret = await deriveKey(newPassphrase, newEmail, saltEnc);
  const oldAuthSecret = await deriveKey(oldPassphrase, oldEmail, saltKey);
  const newAuthSecret = await deriveKey(newPassphrase, newEmail, saltKey);

  // Compute the verifier key and add it to the Account object
  const oldVerifier = oldPassphrase
    ? srp
        .computeVerifier(
          _getSrpParams(),
          Buffer.from(saltAuth, 'hex'),
          Buffer.from(oldEmail, 'utf8'),
          Buffer.from(oldAuthSecret, 'hex'),
        )
        .toString('hex')
    : '';

  const newVerifier = newPassphrase
    ? srp
        .computeVerifier(
          _getSrpParams(),
          Buffer.from(saltAuth, 'hex'),
          Buffer.from(newEmail, 'utf8'),
          Buffer.from(newAuthSecret, 'hex'),
        )
        .toString('hex')
    : '';

  // Re-encrypt existing keys with new secret
  const decrypted = decryptAES(oldSecret, JSON.parse(encSymmetricKey));
  const newEncSymmetricKeyJSON = encryptAES(newSecret, decrypted);

  const newEncSymmetricKey = JSON.stringify(newEncSymmetricKeyJSON);

  return util.post(`/auth/change-password`, {
    verifier: oldVerifier,
    newEmail,
    newFirstName,
    newLastName,
    encSymmetricKey: encSymmetricKey,
    newVerifier,
    newEncSymmetricKey,
  });
}

export type EncryptionKeys = {
  verifier: string;
  publicKey: string;
  encPrivateKey: string;
  encSymmetricKey: string;
  saltAuth: string;
  saltEnc: string;
  saltKey: string;
};

export async function createPassphrase(rawEmail: string, rawPassphrase: string): Promise<EncryptionKeys> {
  const email = _sanitizeEmail(rawEmail);
  const passphrase = _sanitizePassphrase(rawPassphrase);
  const saltEnc = await srp.getRandomHex();
  const saltAuth = await srp.getRandomHex();
  const saltKey = await srp.getRandomHex();

  // Generate some secrets for the user base'd on password
  const authSecret = await srp.deriveKey(passphrase, email, saltKey);
  const derivedSymmetricKey = await srp.deriveKey(passphrase, email, saltEnc);

  // Generate public/private keypair and symmetric key for Account
  const { publicKey: publicKeyObj, privateKey } = await srp.generateKeyPairJWK();
  const symmetricKeyJWK = await srp.generateAES256Key();

  // Compute the verifier key and add it to the Account object
  const verifier = srp
    .computeVerifier(
      _getSrpParams(),
      Buffer.from(saltAuth, 'hex'),
      Buffer.from(email, 'utf8'),
      Buffer.from(authSecret, 'hex'),
    )
    .toString('hex');

  // Encode keypair
  const encSymmetricJWKMessage = srp.encryptAES(derivedSymmetricKey, JSON.stringify(symmetricKeyJWK));
  const encPrivateJWKMessage = srp.encryptAES(symmetricKeyJWK, JSON.stringify(privateKey));

  // Add keys to account
  const publicKey = JSON.stringify(publicKeyObj);
  const encPrivateKey = JSON.stringify(encPrivateJWKMessage);
  const encSymmetricKey = JSON.stringify(encSymmetricJWKMessage);

  return {
    verifier,
    publicKey,
    encPrivateKey,
    encSymmetricKey,
    saltAuth,
    saltEnc,
    saltKey,
  };
}

export class NeedPassphraseError extends Error {
  constructor() {
    super('Passphrase required');

    // This trick is necessary to extend a native type from a transpiled ES6 class.
    Object.setPrototypeOf(this, NeedPassphraseError.prototype);
  }
}

export class InvalidPassphraseError extends Error {
  constructor() {
    super('Invalid password');

    // This trick is necessary to extend a native type from a transpiled ES6 class.
    Object.setPrototypeOf(this, InvalidPassphraseError.prototype);
  }
}

export async function deriveSymmetricKey(params: PrivateKeyParams, rawPassphrase: string): Promise<string> {
  const passPhrase = _sanitizePassphrase(rawPassphrase);
  const { encDriverKey, saltEnc } = params;
  return await deriveKey(passPhrase, encDriverKey, saltEnc);
}

// PrivateKeyParams = Pick<schema.WhoamiResponse & { encDriverKey: string }, 'email' | 'saltEnc' | 'encPrivateKey' | 'encSymmetricKey' | 'encDriverKey'>;
export async function getCachedPrivateKey(params: PrivateKeyParams, rawPassphrase: string | null): Promise<JsonWebKey> {
  let privateKey: string | null = null;

  if (rawPassphrase !== null) {
    // We have a raw passphrase? Derive it from the passphrase.
    const secret = await deriveSymmetricKey(params, rawPassphrase);
    const { encPrivateKey, encSymmetricKey } = params;

    let symmetricKey: string;
    try {
      symmetricKey = srp.decryptAES(secret, JSON.parse(encSymmetricKey));
    } catch (err) {
      Sentry.captureException(err);
      console.log('Failed to decrypt wrapped private key', err);
      throw new InvalidPassphraseError();
    }

    privateKey = srp.decryptAES(JSON.parse(symmetricKey), JSON.parse(encPrivateKey));
    try {
      sessionStorage.setItem('privateKey', privateKey);
    } catch (err) {
      Sentry.captureException(err);
      console.log('Failed to store private key into cache', err);
    }
  } else {
    // Otherwise, try to get it from the cache.
    try {
      privateKey = sessionStorage?.getItem('privateKey');
    } catch (err) {
      Sentry.captureException(err);
      console.log('Failed to fetch private key from cache', err);
    }

    if (privateKey === null) {
      throw new NeedPassphraseError();
    }
  }

  return JSON.parse(privateKey) as JsonWebKey;
}

export async function githubOauthConfig() {
  return util.get<schema.GithubOAuthConfigResponse>('/v1/oauth/github/config', false);
}

export async function updateEmailSubscription(unsubscribed: boolean) {
  if (unsubscribed) {
    return util.post(`/v1/email/unsubscribe`);
  } else {
    return util.post(`/v1/email/subscribe`);
  }
}

export function getSessionKey() {
  return util.get<{ sessionKey: string }>('/v1/sessions/sessionKey');
}

function _getSrpParams() {
  return params[2048];
}

function _sanitizeEmail(email: string) {
  return email.trim().toLowerCase();
}

function _sanitizePassphrase(passphrase: string) {
  return passphrase.trim().normalize('NFKD');
}

const APP_TOKEN_BOX_KEY = 'APP_TOKEN_BOX_KEY';

export function storeTokenBox(box: string) {
  sessionStorage.setItem(APP_TOKEN_BOX_KEY, box);
}

export function getTokenBox() {
  return sessionStorage.getItem(APP_TOKEN_BOX_KEY);
}

export function unsetTokenBox() {
  return sessionStorage.removeItem(APP_TOKEN_BOX_KEY);
}

export async function getKeyPair(): Promise<{
  publicKey: JsonWebKey;
  privateKey: JsonWebKey;
}> {
  return generateKeyPairJWK();
}
